@import "styles";
.homeWrapper{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.mainPage{
  overflow: hidden;
  height: 100vh;
  position: fixed;
  direction: rtl;
}

.sections{
  overflow: hidden;
}

.sliders{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.slide{
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  overflow: hidden;

  .innerSlide{
    width: 100%;
    height: 100%;
    background-size: cover;
    
  }

  .innerSlideLeft{
    background-position: right;
    transform-origin: right center;

  }
  
  .innerSlideRight{
    background-position: left;
    transform-origin: left center;

  }
}
.arrowButton{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 75px;
  margin-left :auto;
  margin-right: auto;
  width: 54px;
  z-index: 10002;

  @include screen('mobile'){
    width: 37px;
    height: 25px;
  }
}

.arrow{
  height: 37px;

  @include screen('mobile'){
    height: 25px;
  }
}

$visibilityTransitionTime: 2s;
.firstSlideLeft{
  transition: visibility $visibilityTransitionTime;
  transform-origin: right center;
  z-index: 10;
  pointer-events:none;

  visibility: visible;

}

.firstSlideRight{
  visibility: visible;
  z-index: 10;
  pointer-events:none;

  transform-origin: left center;
  transition: visibility $visibilityTransitionTime;
  overflow: hidden;

}

.secondSlideLeft{
  transition: visibility $visibilityTransitionTime;
  transform-origin: right center;
  z-index: 5;
  pointer-events:none;

  visibility: hidden;
  overflow: hidden;

}

.secondSlideRight{
  transition: visibility $visibilityTransitionTime;
  z-index: 6;
  pointer-events:none;

  visibility: hidden;
  transform-origin: left center;
  overflow: hidden;
}

.thirdSlideLeft{
  transition: visibility $visibilityTransitionTime;
  visibility: hidden;
  pointer-events:none;

  transform-origin: left center;
  overflow: hidden;
  z-index: 10;
}

.thirdSlideRight{
  transition: visibility $visibilityTransitionTime;
  visibility: hidden;
  pointer-events:none;

  transform-origin: right center;
  overflow: hidden;
  z-index: 4;
}

.fourSlideLeft{
  transition: visibility $visibilityTransitionTime;
  visibility: hidden;
  pointer-events:none;

  transform-origin: left center;
  overflow: hidden;
  z-index: 8;
}

.fourSlideRight{
  transition: visibility $visibilityTransitionTime;
  visibility: hidden;
  pointer-events:none;
  transform-origin: right center;
  overflow: hidden;
  z-index: 10;
}

.fiveSlideLeft{
  transition: visibility $visibilityTransitionTime;
  visibility: hidden;
  pointer-events:none;
  transform-origin: left center;
  overflow: hidden;
  z-index: 9;
}

.fiveSlideRight{
  transition: visibility $visibilityTransitionTime;
  visibility: hidden;
  pointer-events:none;
  transform-origin: right center;
  overflow: hidden;
  z-index: 9;
}

.content{
  height: 100vh;
}

.hidden{
  visibility: hidden;
}

.active{
  visibility: visible;
  pointer-events:auto;

  transform: scale(1);
  
}
.circle{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  top: 0;
  margin: auto auto;
  width: 384px;
  height: 384px;

}
.chart {
  position: relative;
  display: inline-block;
  color: rgb(143, 10, 10);
  font-size: 20px;
  text-align: center;
  left: -7px;
  z-index: 9000;
}

.chart figcaption {
  padding: 50px 25px;
  width: 100px;
  height: 50px;
  border: 20px solid #f0f0f0;
  border-radius: 100px;
  line-height: 50px;

}

.chart img {
  position: absolute;
  max-width: 100px;
  max-height: 100px;
  background: white;
}
/* END Container styles */

/* Colors for the circles and positions for the graphics */

.chart svg {
   width: 400px;
   height: 400px;
   overflow: visible;
}

.outer {
  fill: transparent;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 20;
  stroke-dasharray: 1250;
  transition: stroke-dashoffset 1s;
  animation-play-state: running;
  pointer-events: none;
  
  /* firefox bug fix - won't rotate at 90deg angles */
  transform: rotate(-89deg) translateX(-190px);
}

/* Set the initial values for the animation */


.chart[data-percent='0'] .outer {
  stroke-dashoffset: 1250;
  animation: show50 2s;
}
/* END set initial animation values */

/* Keyframes for the initial animation */


.activeCircle1{
  stroke-dashoffset: 950 !important;
  animation-play-state: paused;

  @include screen('mobile'){
    stroke-dashoffset: 1010 !important;
  }
}

.activeCircle2{
  stroke-dashoffset: 662 !important;
  animation-play-state: paused;

  @include screen('mobile'){
    stroke-dashoffset: 785 !important;
  }
}

.activeCircle3{
  stroke-dashoffset: 350 !important;
  animation-play-state: paused;

  @include screen('mobile'){
    stroke-dashoffset: 545 !important;
  }
}
.activeCircle4{
  stroke-dashoffset: 0 !important;

  animation-play-state: paused;
}

@keyframes show100 {
  from {
    stroke-dashoffset: 537;
  }
  
  to {
    stroke-dashoffset: 0;
  }
}


@keyframes show75 {
  from {
    stroke-dashoffset: 537;
  }
  
  to {
    stroke-dashoffset: 124;
  }
}



@keyframes show50 {
  from {
    stroke-dashoffset: 537;
  }
  
  to {
    stroke-dashoffset: 267;
  }
}


@keyframes show25 {
  from {
    stroke-dashoffset: 537;
  }
  
  to {
    stroke-dashoffset: 401;
  }
}
/* END Keyframes for the initial animation */

