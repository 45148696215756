@import "styles";

$loaderWidth: 250;

@keyframes circleAnimation {
  0% {transform:scale(0.5)}
  60% {transform: scale(1.4)}
  85% {transform: scale(1.2)}
  100%{transform:scale(0.3)}
}

.loader{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10004;
  display: flex;
  justify-content: center;
  align-items: center;
 

  &::before{
      content: '';
      position:absolute;
      top:0px; 
      left:0px;
      right: 0;
      bottom: 0;
      margin: auto;
      width:0; 
      height:0;
      border-radius:50%;
      box-shadow: 0px 0px 0px 9999px #fff;
      z-index:-1;
      transition: 1s;
      transition-delay: 0.7s;
  }

  .innerLoader{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: vw_d($loaderWidth);
    height: vw_d($loaderWidth);
    text-align: center;
    transition: opacity 1s;
    transition-delay: 0.5s;

    @include screen('mobile'){
      width: vw($loaderWidth);
      height: vw($loaderWidth);
    }

    .loaderText{
      display: flex;
      z-index: 20;
      color: #fff;
      font-family: $heeboFont;
      font-weight: 800;
      font-size: vw_d(25);
      direction: rtl;

      @include screen('mobile'){
        font-size: vw(25);
      }

      div{
        display: flex;
      }
    }
  }
  .circle{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #ffd50042;
    border-radius: 50%;
    height: vw_d($loaderWidth);
    width: vw_d($loaderWidth);
    animation: circleAnimation 4s;

    @include screen('mobile'){
      height: vw($loaderWidth);
      width: vw($loaderWidth);
    }
  }

  .transparentCircle{
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    height: vw_d(500);
    width: vw_d(500);
    transition-delay: 3s;
    animation: circleAnimation 4s infinite;
    z-index: 20000;

  }

  .innerCircle{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    height: vw_d(150);
    width: vw_d(150);
    background-color: #ffd500;
    z-index: 10;

    @include screen('mobile'){
      height: vw(150);
      width: vw(150);
    }

  }

}

.disable{
  .innerLoader{
    opacity: 0;
  }
  &::before{
    width: vw_d(2000);
    height: vw_d(2000);
    transform: scale(2)

    
  }
  @include screen('mobile'){
    &::before{
      width: vw(1500);
      height: vw(1500);
    }
  }
}

.displayNone{
  display: none;
}





