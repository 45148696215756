@import "styles";

.button {
	z-index: 50;
	width: vw_d(33);
	transition: none;
	border-radius: 50%;
	opacity: 1;
	transition: 0.3s;
		margin-right: vw_d(20);


	@include screen('mobile') {
		width: vw(56);
		height: auto;
		margin-right: 10px;
	}

	&:hover {
		opacity: 0.8;
	}

	svg {
		width: 100%;
		height: 100%;
	}

  .accessibilityImg{
    width: vw_d(37);

    @include screen('mobile'){
      width: 28px;
    }
  }
}

// Accessibility Styles
:global {
	body {
		&.acc-grayscale,
		&.acc-negative {
			overflow-x: hidden;
		}
	}

	div#accessibility {
		position: absolute !important;
		z-index: 45 !important;
		border: 0 !important;
		background: transparent !important;
		box-shadow: none !important;

		@include screen('non-mobile') {
			top: 29px !important;

			html[dir='ltr'] & {
				right: 65px !important;
				left: auto !important;
			}

			html[dir='rtl'] & {
				right: auto !important;
				left: 65px !important;
			}
		}

		@include screen('mobile') {
			top: auto !important;
			right: vw(10 * 2) !important;
			bottom: vw(15 * 2) !important;
			left: auto !important;
			z-index: 45 !important;
		}

		div#acc-header {
			.image {
				background: none !important;

				@include screen('mobile') {
					right: 0 !important;
					left: auto !important;

					html[dir='rtl'] & {
						right: 0 !important;
						left: auto !important;
					}
				}

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					display: block;
					width: 25px;
					height: 25px;
					transform: translate(-50%, -50%);
				}

				html[dir='ltr'] & {
					right: 0 !important;
					left: auto !important;
				}

				html[dir='rtl'] & {
					right: auto !important;
					left: 0 !important;
				}

				img {
					display: none !important;
				}
			}

			.text {
				visibility: hidden;
			}
		}

		button#acc-regular {
			@include screen('non-mobile') {
				&:focus {
					outline: transparent auto 5px !important;
				}
			}
		}

		div#acc-footer {
			display: none !important;
		}
	}

	input,
	textarea {
		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px white inset;
			color: black;
		}
	}

	.overflow {
		overflow: hidden;
	}

	#INDmenu-btn {
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 34px;
			height: 34px;
			border-radius: 50%;
			// background-color: red;
			// border: 1px solid red;

			@include screen('mobile') {
				width: vw(23 * 2);
				height: vw(23 * 2);
				background-color: white;
			}
		}
	}

	#INDbtnWrap {
		#INDmenu-btn {
			position: fixed;
			top: vw_d(20) !important;
			right: initial;
			left: vw_d(20) !important;
			width: vw_d(40);
			height: vw_d(40);
			margin: 0;
			transition: none !important;
			opacity: 0 !important;
			visibility: hidden !important;

			@include screen('mobile') {
				position: absolute;
				top: vw(19) !important;
				left: vw(20) !important;
				width: vw(44);
				height: vw(44);
			}
		}
	}

	#INDmenu-btn svg {
		visibility: hidden;
	}
}
