@import 'styles';

.socials {
	position: relative;
	z-index: 51;
	display: flex; 
  align-items: center;
  justify-content: center;
	height: 100%;
	// margin: 0 vw_d(12);
	cursor: pointer;

	.socialButton {
		display: inline-block;
		width: vw_d(32);
		margin-right: vw_d(20);
		align-items: center;
		cursor: pointer;
		opacity: 1;
		transition: 0.3s;

    &:last-child{
      margin-right: 0;
    }

		@include screen('mobile') {
			width: vw(23);
			margin: 0 vw(16);
		}

		// &:first-child {
		// 	margin: vw_d(6) 0 0 2px;
		// }

		svg {
			width: 100%;
			height: auto;
		}

		img {
			width: 100%;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	.overflowWrap {
		display: flex;
		align-items: center;
    justify-content: flex-end;

		@include screen('mobile') {
			display: flex;
			width: vw(296);
			justify-content: space-around;
		}

		.icon {
			&-email {
				width: vw_d(36);
				margin-right: vw_d(-8);
				margin-bottom: vw_d(-4);

				@include screen('mobile') {
					width: vw(32);
					margin-right: auto;
					margin-bottom: vw(-4);
				}
			}

			&-whatsapp {
				@include screen('mobile') {
				}
			}
		}
	}

	&.minified {
		&.open {
			.overflowWrap {
				visibility: visible;
				opacity: 1;
				transform: scale(1);

				@include screen('mobile') {
					position: absolute;
					top: vw(-118);
				}
			}
		}

		.overflowWrap {
			position: relative;
			transition: all 0.25s ease-in-out;
			visibility: hidden;
			opacity: 0;

			@include screen('mobile') {
				position: absolute;
				top: vw(-148);
				left: vw(-138);
				display: flex;
				width: vw(296);
				height: vw(296);
				padding: vw(130) vw(0) 0 vw(0);
				padding-top: vw(0);
				justify-content: space-around;
				transition: all 0.3s ease;
				background: #575a68;
				border-radius: vw(148);
				box-shadow: 0 12px 40px 0 rgba(21, 37, 154, 0.23);
				transform: scale(0);
			}
		}

		.socialButton {
			width: vw(50);
			margin: 0 0 0 0;
      position: absolute;

      &:nth-child(1){
        left: vw(35);
        top: vw(150);
      }

      &:nth-child(2){
        left: vw(100);
        top: vw(220);
      }
		}

		.toggleBtn {
      display: flex;
      justify-content: center;
      align-items: center;
			z-index: 1;
			width: vw(28);
			height: 100%;
			// background-color: rgb(254, 128, 92);
			border-radius: 50%;

			svg {
				width: auto;
				height: auto;
			}
		}
	}

  
}

.accessibility{
  width: vw_d(32);
  margin-right: vw_d(20);

  .accessibilityImg{
    width: vw_d(32);

    @include screen('mobile'){
      width: 28px;
    }
  }

  @include screen('mobile'){
    margin-right: 15px;
    width: 28px;
  }
}
