@import 'styles';
.title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  right: 0;
  color: #fff;
  font-size: vw_d(100);
  z-index: 10001;
  font-family: $heeboFont;
  font-weight: 800;
  line-height: vw_d(70);
  text-shadow: 0 0 80px rgba(41, 0, 255, 0.64);

  .innerTitleBlock{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 200px;
    
    .title{
      position: absolute;
      direction: rtl;
      display: flex;
      font-size: 100px;
      line-height: 70px;
      letter-spacing: -2px;
      margin-bottom: 20px;
      transition-timing-function: cubic-bezier(0.13, 0.7, 0.42, 1);
      transition: opacity .4s, transform 1.3s;
      transform: translateX(100px);
      opacity: 0;
      text-align: center;
      width: 0;
      white-space: nowrap;
      pointer-events: none;


      @include screen('mobile'){
        font-size: 77px;
      }

    }

    .firstTitle{
      font-size: 113px;
      letter-spacing: 22.6px;
      transform: translateY(-50px);
      font-weight: 600;
      width: 0;
      white-space: nowrap;

      @include screen('mobile'){
        font-size: vw(105);
        letter-spacing: 3.4px;
        bottom: 33px;
      }
    }

    .lastTitle{
      line-height: 80px;

      @include screen('mobile'){
        line-height: 62px;
      }
    }

    .description{
      font-size: 30px;
      font-weight: 400;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      line-height: 36px;
      position: absolute;
      bottom: 30px;
      opacity: 0;
      transform: translateY(100px);
      transition-timing-function: cubic-bezier(0.13, 0.7, 0.42, 1);
      transition: opacity .5s, transform 1.3s;
      width: 230px;
      white-space: nowrap;
      pointer-events: none;

      @include screen('mobile'){
        font-size: 20px;
      }

    }

    .firstDescription{
      font-size: 27px;
      width: 580px;
      bottom: -20px;

      @include screen('mobile'){
        font-size: vw(38);
        line-height: 28px;
        bottom: -74px;
        width: 100%;

      }
    }

    .secondDescription{
      bottom: 0px;
      @include screen('mobile'){
        bottom: 8px;
      }
    }

    .thirdDescription{
      bottom: 20px;

      @include screen('mobile'){
        bottom: 40px;
      }
    }

    .fourDescription{
      bottom: 20px;

      @include screen('mobile'){
        bottom: 40px;
      }
    }

    .fiveDescription{
      bottom: -15px;

      @include screen('mobile'){
        bottom: 15px;
      }
    }

    
    
    .leftTransform{
      transform: translateX(-100px);
    }

    .rightTransform{
      transform: translateX(100px);
    }

    .bottomTransform{
      transform: translateY(50px)
    }

    .circleTitle{
      display: flex;
      z-index: 23;
    }

    .active{
      transform: translateX(0) translateY(0);
      opacity: 1;
    }
  }
  
  .ellipse{
    width: vw_d(384);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

.link{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  pointer-events: none;
}

svg {
  position: absolute;
  width: vw_d(384);
  height: vw_d(384);
}

circle {
  fill: none;
  stroke-width: 480px;
}

.titleWrapper{
  overflow: hidden;
}

.activeTitle{
  pointer-events: auto;

}
