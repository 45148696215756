@import "styles";

.footer {
  height: vw_d(50);
  background-color: #030303;

  @include screen("mobile") {
    height: vw(70);
  }

  p {
    font-size: vw_d(17);
  }

  img {
    height: vw_d(25);
  }

  .right {
    padding-left: vw_d(13);
  }

  .left {
    padding-right: vw_d(18);
    border-right: solid white 1px;

    p {
      margin-left: vw_d(6);
    }
  }

  @include screen("mobile") {
    p {
      font-size: vw(22);
      margin-left: vw(6);
    }

    .right {
      padding-left: vw(5);
    }

    .left {
      padding-right: vw(10);
      height: vw(18);
    }

    img {
      height: vw(30);
      margin-right: vw(6);
    }
    margin: 0;
  }
}
