@import "styles";

.header {
  padding: vw_d(20) vw_d(30);
  z-index: 10002;
  height: vw_d(76);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  transition: 0.3s;

  @include screen("mobile") {
    height: vw(100);
    padding-left: vw(16.7);
    padding-right: vw(20);
  }

  &.hidden {
    top: vw_d(-116);

    @include screen("mobile") {
      top: vw(-100);
    }
  }

  .right {
    display: flex;
    align-items: center;

    img {
      height: vw_d(30);
      margin: 0 vw_d(8);
    }
  }

  .left {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .link {
    margin-right: 10px;
  }

  .ynet {
    height: 28px;

    @include screen("mobile") {
      height: vw(50);
    }
  }

  .poweredBy {
    height: 30px;

    @include screen("mobile") {
      height: vw(45);
    }
  }

  .asus {
    height: 19.7px;
    @include screen("mobile") {
      height: vw(25);
    }
  }

  .oled {
    height: 31px;

    @include screen("mobile") {
      height: vw(50);
    }
  }

  .intel {
    height: 31px;
    @include screen("mobile") {
      height: vw(50);
    }
  }
}
