@import "styles";

.home {
  .title {
    font-size: vw_d(138);
    margin-top: vh_d(680);

    @include screen("mobile") {
      font-size: vw(131);
      margin-top: vw(640);
      line-height: vw(108);
    }
  }
  .subTitle {
    font-size: vw_d(30);
    margin-top: vh_d(-40);
    font-weight: 900;
    letter-spacing: vw_d(6);
    @include screen("mobile") {
      font-size: vw(30);
      margin-top: vw(22);
      line-height: vw(40);
      letter-spacing: vw(6);
    }
  }
}

.content {
  font-size: vw_d(40);
  direction: rtl;
  line-height: vw_d(50);

  @include screen("mobile") {
    font-size: vw(30);
    line-height: vw(40);
  }

  &.mobile {
    width: 66.67%;
  }

  &1 {
    width: vw_d(978);
  }

  &2 {
    width: vw_d(1016);
  }

  &3 {
    width: vw_d(968);
  }

  &5 {
    width: vw_d(1002);
  }

  &6 {
    width: vw_d(1040);
  }

  &7 {
    width: vw_d(978);
  }

  &8 {
    width: vw_d(1203);
  }
}
.image1 {
  width: vw_d(600);
}

.image2 {
  width: vw_d(1258);
}

.image6 {
  width: vw_d(600);
}

.image5 {
  width: vw_d(1258);
}

.imageMobile {
  width: vw(563);
}

.scrollIcon {
  width: vw_d(54);
  bottom: vw_d(60);
  animation: anim alternate 1s infinite ease-in;

  @include screen("mobile") {
    width: vw(66);
    bottom: vw(90);
  }
}

.video {
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  max-width: auto;
}

.pagination {
  bottom: vh_d(71);

  @include screen("mobile") {
    bottom: vw(92);
  }

  .dot {
    width: vw_d(16);
    height: vw_d(16);
    margin: 0 vw_d(8);

    @include screen("mobile") {
      width: vw(24);
      height: vw(24);
      margin: vw(12);
    }
  }
}

@keyframes anim {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(vw_d(60));
  }
}
