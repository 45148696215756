@import "styles";

.rightMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  align-items: flex-end;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: vw_d(24);
  height: vw_d(240);
  z-index: 1000;

  @include screen("mobile") {
    top: auto;
    right: vw(35);
    bottom: vw(56);
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .minimizedButton {
    display: flex;
    justify-content: flex-end;
    transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1.5);
    transition: 0.5s;
    transform-origin: vw_d(15) vw_d(15);
    width: vw_d(30);
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.418);
    border-radius: 50%;

    @include screen("mobile") {
      width: vw(50);
      transform-origin: vw(25) vw(25);
    }

    &.hover {
      transform: rotate(180deg) scale(1.3);
    }
  }

  .first {
    width: vw_d(30);
    height: vw_d(30);

    @include screen("mobile") {
      width: vw(50);
      height: vw(50);
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: 0.5s;
    transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1.5);
    margin-top: vw_d(21);

    @include screen("mobile") {
      transform: translateY(vw(-320));
      transform: translateX(vw(25));
      opacity: 0;
      visibility: hidden;
    }
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: vw_d(30);
    height: vw_d(30);
    margin-bottom: vw_d(21);
    font-family: $heeboFont;
    font-weight: 700;
    transition-timing-function: cubic-bezier(0, 1.5, 1, 1.5);
    transition: 0.5s;
    font-size: vw_d(16);
    white-space: nowrap;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.418);

    &:last-child {
      margin-bottom: 0;
    }

    @include screen("mobile") {
      height: vw(38.2);
      width: auto;
      font-size: vw(18);
      pointer-events: none;
      margin-bottom: vw(25);

      &:last-child {
        margin-bottom: vw(40);
      }
    }

    span {
      opacity: 0;
      visibility: hidden;
      transition: 0.4s;
    }

    &.hover {
      span {
        opacity: 1;
        visibility: visible;
      }
    }

    // &:hover {
    //   span {
    //     opacity: 1;
    //     visibility: visible;
    //   }
    // }

    // span {
    //   transition: opacity 0.8s;
    //   opacity: 0;
    //   visibility: hidden;

    //   @include screen("mobile") {
    //     opacity: 1;
    //     visibility: visible;
    //   }
    // }
  }

  .yellow {
    background-color: #ffd500;

    @include screen("desktop") {
      &.hover {
        width: vw_d(135);
      }
    }

    @include screen("mobile") {
      width: vw(150);
    }
  }

  .green {
    background-color: #3bffb1;

    @include screen("desktop") {
      &.hover {
        width: vw_d(88);
      }
    }

    @include screen("mobile") {
      width: vw(100);
    }
  }

  .purple {
    background-color: #f350f8;
    @include screen("desktop") {
      &.hover {
        width: vw_d(72);
      }
    }

    @include screen("mobile") {
      width: vw(100);
    }
  }

  .blue {
    background-color: #20ffff;
    @include screen("desktop") {
      &.hover {
        width: vw_d(135);
      }
    }

    @include screen("mobile") {
      width: vw(150);
    }
  }
}

.isMinimized {
  .links {
    transform: translateX(vw(0));

    opacity: 1;
    visibility: visible;
  }

  .circle {
    pointer-events: all;
  }
}
