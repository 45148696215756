/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/

$green-primary: #00a87e;
$green-dark: #00855f;

$heeboFont: "Heebo", sans-serif;
@function vw($val, $base: 640) {
  @return $val / $base * 100 + vw;
}
@function vh($val, $base: 1080) {
  @return $val / $base * 100 + vw;
}

@function vw_d($val, $base: 1920) {
  @return $val / $base * 100 + vw;
}

@function vh_d($val, $base: 1080) {
  @return $val / $base * 100 + vh;
}

// MEDIA QUERIES:

$media-mobile-max: 767;
$media-tablet-min: 768;
$media-tablet-max: 1024;
$media-desktop-min: $media-tablet-max + 1;
$media-desktop-hd-min: 1920;
$media-mobile: "(max-width: #{$media-mobile-max}px)";
$media-mobile-landscape: "(max-width: #{$media-mobile-max}px) and (orientation: landscape)";
$media-mobile-portrait: "(max-width: #{$media-mobile-max}px) and (orientation: portrait)";

$media-tablet: "(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px)";
$media-tablet-landscape: "(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: landscape)";
$media-tablet-portrait: "(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: portrait)";

$media-desktop: "(min-width: #{$media-desktop-min}px)";
$media-desktop-hd: "(min-width: #{$media-desktop-hd-min}px)";

$media-non-mobile: "(min-width: #{$media-mobile-max}px)";
$media-non-desktop: "(max-width: #{$media-tablet-max}px)";
